import styled from 'styled-components'
import { Column } from 'components/v2/Grid'

export const Element = styled(Column)`
  &:before {
    content: '';
    width: 100%;
    height: 2px;
    background: #353506;
    transform: translate(0, 21px);
    position: relative;
    z-index: 0;
  }

  img {
    position: relative;
  }

  &:first-child {
    &:before {
      width: 50%;
      transform: translate(100%, 21px);
    }
  }

  &:last-child {
    &:before {
      width: 50%;
      transform: translate(-50%, 21px);
    }
  }

  @media (max-width: 1024px) {
    &:before {
      display: none;
    }
  }
`
