import axios from 'axios'
import moment from 'moment'

import Home from 'containers/Home'

const HomePage = ({ posts }) => <Home posts={posts} />

export const getServerSideProps = async () => {
  try {
    const { data: blogPosts } = await axios.get(
      'https://www.vittude.com/blog/wp-json/wp/v2/posts?_embed&per_page=3&page=1'
    )

    const posts = blogPosts?.map(post => {
      const {
        date,
        title,
        id,
        link,
        excerpt,
        content,
        _embedded: {
          'wp:term': [[category]]
        }
      } = post

      return {
        image: _embedded['wp:featuredmedia']?.[0]?.source_url,
        date: moment(date).format('DD [de] MMMM [de] YYYY'),
        title: title.rendered,
        id,
        link,
        excerpt: excerpt.rendered,
        readTime: content.rendered.replace(/(<([^>]+)>)/gi, '').length / 1042,
        category: category.name
      }
    })

    return {
      props: {
        posts
      }
    }
  } catch (error) {
    console.log(error)
    return {
      props: {
        posts: []
      }
    }
  }
}

export default HomePage
