import { Button } from 'components/v2/Button'
import { Text } from 'components/v2/Text'
import { IconContainer, ToastWrapper } from './styles'
import { Row } from 'components/v2/Grid'
import { Close } from 'components/v2/Icons'

const Toast = ({ handleModal, closeToast }) => {
  return (
    <ToastWrapper>
      <Row mb='10px' justifyContent='space-between'>
        <Text fontWeight='600'>Experimente o Vittude Match!</Text>
        <IconContainer onClick={closeToast}>
          <Close size={14} />
        </IconContainer>
      </Row>
      <Row>
        <Text fontSize='14px'>
          O Vittude Match é uma ferramenta que utiliza inteligência artificial para ajudar você a
          encontrar o psicólogo ideal.
        </Text>
      </Row>
      <Row>
        <Button variant='tertiary' p='0' onClick={() => handleModal(true)}>
          Iniciar
        </Button>
      </Row>
    </ToastWrapper>
  )
}

export default Toast
