import React, { useState } from 'react'
import dynamic from 'next/dynamic'

import { Row, Column } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { LinkComponent } from 'components/v2/Link'
import { testimonialsEnterprisePost } from 'utils/constants'
import { Image } from './styles'

const LazyImage = dynamic(() => import('components/shared/LazyImage'), {
  ssr: false
})

const Testimonials = () => {
  const [count, setCount] = useState(0)

  const selectSlide = number => {
    setCount(number)
  }

  return (
    <section id='on-media'>
      <Row py='5' bg='#F9FAFD'>
        <Row px='3' maxWidth='1200px' m='0 auto' flexWrap='wrap' flexDirection='column'>
          <Row flexWrap='wrap' pb='61px' justifyContent='space-between' alignItems='center'>
            {testimonialsEnterprisePost.map((post, index) => (
              <Column key={index} onClick={() => selectSlide(index)}>
                <Image>
                  <LazyImage
                    className={count === index ? 'lazy-img active-logo' : 'lazy-img'}
                    src={{
                      webp: post.image_webp,
                      png: post.image
                    }}
                    alt={`Na mídia ${index}`}
                  />
                </Image>
              </Column>
            ))}
          </Row>
          <Row>
            {testimonialsEnterprisePost.map((post, index) => (
              <Row
                key={index}
                justifyContent='space-between'
                display={count === index ? 'flex' : 'none'}
                flexWrap='wrap'
                minWidth='100%'
              >
                <Text variant='regular' color='#293450' fontSize='16px' lineHeight='150%'>
                  {post.text}{' '}
                  <LinkComponent
                    display='inline-flex'
                    href={post.href}
                    label='Ler matéria'
                    color='primary'
                    target='__blank'
                    ml='5px'
                    id='media-read-article'
                    icon
                    textDecoration='none'
                  />
                </Text>
              </Row>
            ))}
          </Row>
        </Row>
      </Row>
    </section>
  )
}

export default Testimonials
