import { useState } from 'react'
import Router from 'next/router'

import { Column } from 'components/v2/Grid'
import { Button } from 'components/v2/Button'
import { Text } from 'components/v2/Text'
import { NextImage } from 'components/v2/NextImage'

import { useDetectMobileDeviceOS, useBodyScrollLock } from 'hooks'
import { VITTUDE_APP_STORE_LINK } from 'utils/constants'

import styled from 'styled-components'

export const DownloadAppCard = () => {
  const [isDownloadAppCardOpen, setIsDownloadAppCardOpen] = useState(true)

  const { deviceOS, isMobile } = useDetectMobileDeviceOS()
  const validacaoMobile = isMobile && isDownloadAppCardOpen

  useBodyScrollLock(validacaoMobile, [isMobile, isDownloadAppCardOpen])

  const handleDownloadAppClick = () => {
    Router.push(VITTUDE_APP_STORE_LINK[deviceOS])
    setIsDownloadAppCardOpen(false)
  }

  return (
    <>
      {isMobile && isDownloadAppCardOpen && (
        <>
          <Container id='DownloadAppCard'>
            <VittudeAppLogo src='/static/images/app/vittude-app.webp' />
            <Text
              fontSize='16px'
              fontWeight='600'
              lineHeight='24px'
              letterSpacing='0.15px'
              mb='24px'
            >
              Baixe o app da Vittude para gerenciar sua agenda e fazer seus agendamentos mais
              rápidos
            </Text>
            <Column gridGap='8px' width='100%'>
              <Button
                variant='primary'
                height='56px'
                fontSize='14px'
                width='100%'
                fontWeight={600}
                borderRadius='10px'
                onClick={handleDownloadAppClick}
                display='flex'
                alignItems='center'
                justifyContent='center'
                gridGap='8px'
              >
                Instalar aplicativo
                <NextImage src='/static/images/icon-download.svg' width='24px' height='24px' />
              </Button>
              <Button
                variant='primary-outlined'
                borderColor='#E7EAF4'
                color='black'
                fontSize='14px'
                height='56px'
                fontWeight='600'
                width='100%'
                borderRadius='10px'
                onClick={() => setIsDownloadAppCardOpen(false)}
              >
                Agora não
              </Button>
            </Column>
          </Container>
          <Overlay onClick={() => setIsDownloadAppCardOpen(false)} />
        </>
      )}
    </>
  )
}

const Container = styled(Column)`
  background-color: white;
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 24px;
  padding-top: 40px;
  text-align: center;
  align-items: center;
`

const VittudeAppLogo = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: -72px;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 90;
  background: #00000045;
  position: fixed;
`
