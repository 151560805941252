import styled from 'styled-components'

export const ImageBox = styled.div`
  transition: 0.6s;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  border-radius: 15px;
  height: 242px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 214px;
  }
`

export const LinkBlog = styled.a`
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
`
