import { useEffect, useState } from 'react'
import Link from 'next/link'

import { Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { StarFilled } from 'components/v2/Icons/StarFilled'

import { getPreviewEvaluations } from 'services/evaluation'
import {
  CommentSection,
  DotContainer,
  DesktopCommentsCards,
  CommentTextDesktop,
  TextLink,
  Dot
} from './styles'
import { checkIsMobile } from 'utils/helpers'

const fiveStarsDesk = Array(5).fill(<StarFilled height='22px' width='24px' />)

const MAX_PAGES = 6
const SHOW_PER_PAGE_DESKTOP = 3
const SHOW_PER_PAGE_MOBILE = 1

const arrayGroup = (array, subGroupLength) => {
  let index = 0
  const newArray = []

  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }

  return newArray
}

const KnowMore = () => {
  const [comments, setComments] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const isMobile = checkIsMobile(993)

  const SHOW_PER_PAGE = isMobile ? SHOW_PER_PAGE_MOBILE : SHOW_PER_PAGE_DESKTOP

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await getPreviewEvaluations({
          per_page: 6,
          min_stars: 5,
          page
        })
        setComments(arrayGroup(data.results, SHOW_PER_PAGE)[page - 1])
        setTotalPages(
          MAX_PAGES > Math.ceil(data.results.length / SHOW_PER_PAGE)
            ? Math.ceil(data.results.length / SHOW_PER_PAGE)
            : MAX_PAGES
        )
      } catch (error) {
        console.log(error)
      }
    })()
  }, [page])

  const handlePageChange = page => {
    setPage(page)
  }

  return (
    <CommentSection py='5'>
      <Text fontSize={['24px', '32px']} mb='16px'>
        E quem já faz terapia?
      </Text>
      <Text mb='5' fontSize={['16px', '24px']}>
        Confira os últimos comentários dos nossos pacientes sobre os psicólogos que atendem na
        Vittude
      </Text>
      <Row>
        {comments.map(({ comment, consultation, created, id }) => (
          <DesktopCommentsCards key={`comment-${id}`}>
            <Row>
              <CommentTextDesktop height='140px'>
                <Text lineHeight='24px'>"{comment}"</Text>
              </CommentTextDesktop>
            </Row>
            <Row mb='14px' mt='10px'>
              {fiveStarsDesk.map(star => star)}
            </Row>
            <Text color='secondary' fontWeight='bold' mb='8px'>
              {consultation.patient.name}
            </Text>
            <Text color='secondary' mb='16px'>
              Paciente de{' '}
              <Link href={`/psicologo/${consultation.professional.slug}`}>
                <TextLink>{consultation.professional.name}</TextLink>
              </Link>
            </Text>
          </DesktopCommentsCards>
        ))}
      </Row>
      <DotContainer>
        {Array(totalPages)
          .fill(0)
          .map((_, index) => (
            <Dot
              key={`dot-${index}`}
              onClick={() => handlePageChange(index + 1)}
              active={page === index + 1}
            />
          ))}
      </DotContainer>
    </CommentSection>
  )
}

export default KnowMore
