import React from 'react'
import { Row, Column } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { Button } from 'components/v2/Button'
import LazyImage from 'components/shared/LazyImage'
import { Element } from './styles'

const ScheduleAppointmentHome = () => (
  <section id='schedule-appointment'>
    <Row flexWrap='wrap' background='#4D4D00' py='5' color='white'>
      <Row px='3' maxWidth='1200px' margin='0 auto' flexWrap='wrap' justifyContent='center'>
        <Text lineHeight='38px' mb='50px' textAlign='center' fontSize={['24px', '32px']}>
          Como começar minha terapia online
        </Text>
        <Row flexWrap='wrap' minWidth='100%' pb='50px' alignItems='center' mb='48px'>
          <Element sm='12' md='4' lg='3' justifyContent='center' pb={[4, 0]}>
            <LazyImage
              loading='lazy'
              height={42}
              src='/static/images/home-vector1.svg'
              alt='ícone de pesquisa'
            />
            <Text textAlign='center' mt='24px'>
              Encontre seu psicólogo com o Vittude Match
            </Text>
          </Element>
          <Element sm='12' md='4' lg='3' alignItems='center' justifyContent='center' pb={[4, 0]}>
            <LazyImage
              loading='lazy'
              height={42}
              src='/static/images/home-vector3.svg'
              alt='calendário'
            />
            <Text textAlign='center' mt='24px'>
              Escolha o dia e horário mais convenientes para você
            </Text>
          </Element>
          <Element sm='12' md='4' lg='3' alignItems='center' justifyContent='center' pb={[4, 0]}>
            <LazyImage
              loading='lazy'
              height={42}
              src='/static/images/home-vector2.svg'
              alt='calendário'
            />
            <Text textAlign='center' mt='24px'>
              Defina o método de pagamento mais confortável para você
            </Text>
          </Element>
          <Element sm='12' md='4' lg='3' alignItems='center' justifyContent='center' pb={[4, 0]}>
            <LazyImage
              loading='lazy'
              height={42}
              src='/static/images/home-vector4.svg'
              alt='calendário'
            />
            <Text textAlign='center' mt='24px'>
              Em um lugar tranquilo, faça sua sessão do computador ou celular
            </Text>
          </Element>
        </Row>
        <Row flexWrap='wrap' minWidth='100%' justifyContent='center'>
          <a href={'/encontre-seu-psicologo'}>
            <Button
              height='48px'
              width={['100%', 'auto']}
              fontWeight='600'
              variant='light'
              id='choose-psychologist'
            >
              Escolher meu psicólogo
            </Button>
          </a>
        </Row>
      </Row>
    </Row>
  </section>
)

export default ScheduleAppointmentHome
