import { Row, Column } from 'components/v2/Grid'
import styled from 'styled-components'

export const CommentSection = styled(Column)`
  width: 100%;
  align-items: center;
  background: #fa0;
  color: #232936;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 64px 16px;
    text-align: center;
  }
`

export const BaseCard = styled(Column)`
  background: white;
  color: black;
  border-radius: 6px;
  padding: 16px;
  margin: 0 8px;
`

export const DesktopCommentsCards = styled(BaseCard)`
  width: 100%;
  max-width: 360px;
`

export const CommentTextBase = styled(Column)`
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
`

export const CommentTextDesktop = styled(CommentTextBase)`
  -webkit-line-clamp: 6;
`

export const TextLink = styled.a(
  ({ theme: { colors } }) => `
  color: ${colors.primary};
  font-weight: 600;
  `
)

export const DotContainer = styled(Row)`
  margin-top: 20px;
`

export const Dot = styled.div(
  ({ theme: { colors }, active }) => `
  width: ${active ? '46px' : '24px'};
  height: 8px;
  border-radius: 2px;
  background: ${active ? colors.primary : 'white'};
  margin-right: 8px;
  cursor: pointer;
  transition: 0.3s width;
`
)
