import { Column, Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { Image } from 'components/v2/Image'
import { StyledSlider } from './styles'

const carouselItems = [
  {
    name: 'Grupo Boticário',
    src: '/static/images/grupoboti-home-b2b.webp',
    height: '45px'
  },
  {
    name: 'Vivo',
    src: '/static/images/vivo-home-b2b.webp',
    height: '45px'
  },
  {
    name: 'Loreal',
    src: '/static/images/loreal-home-b2b.webp',
    height: '40px'
  },
  {
    name: 'Saint Gobain',
    src: '/static/images/saintgobain-home-b2b.webp',
    height: '90px'
  },
  {
    name: 'Olist',
    src: '/static/images/olist-home-b2b.webp',
    height: '35px'
  },
  {
    name: 'Cassi',
    src: '/static/images/cassi-home-b2b.webp',
    height: '45px'
  },
  {
    name: 'Ambev',
    src: '/static/images/ambev-home-b2b.webp',
    height: '35px'
  }
]

const settings = {
  variableWidth: true,
  slidesToScroll: 1,
  infinite: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2
      }
    }
  ]
}

const OurClientsCarousel = () => {
  return (
    <section id='carousel-medias'>
      <Row py={['64px', '80px']}>
        <Row
          px={['17px', '10px']}
          maxWidth='1200px'
          margin='0 auto'
          justifycontent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Row mb='24px'>
            <Text fontSize='20px' fontWeight='600' textAlign='center'>
              Impactamos mais de 200 mil vidas através de nossos clientes
            </Text>
          </Row>
          <Column maxWidth='calc(100vw - 36px)' width='100%'>
            <StyledSlider {...settings}>
              {carouselItems.map(item => (
                <Column mt={['16px', '0px']} mx={['20px', '40px']} key={item.src}>
                  <Image
                    height={item.height}
                    width={item.width}
                    src={item.src}
                    alt={`Logo ${item.name}`}
                  />
                </Column>
              ))}
            </StyledSlider>
          </Column>
        </Row>
      </Row>
    </section>
  )
}

export default OurClientsCarousel
