import React, { useEffect, useRef, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useHotkey } from '../../../hooks'

const Modal = ({
  children,
  withBackground,
  isOpened,
  classes,
  shouldCloseOnEscape,
  fadeOut,
  justifyContent,
  setIsOpened = () => {},
  setFadeOut = () => {}
}) => {
  const containerRef = useRef()
  const innerModalRef = useRef()

  const [mounted, mountedSet] = useState(false)

  const handleClose = useCallback(() => {
    setFadeOut(true)
    setIsOpened(false)
  }, [fadeOut])

  useEffect(() => {
    if (!isOpened) setFadeOut(false)
  }, [isOpened])

  useHotkey('Escape', () => shouldCloseOnEscape && isOpened && handleClose())

  useEffect(() => {
    containerRef.current = document.createElement('div')
    document.body.appendChild(containerRef.current)

    mountedSet(true)

    return () => {
      document.body.removeChild(containerRef.current)
      mountedSet(false)
    }
  }, [])

  if (!isOpened || !mounted) {
    return null
  }

  return ReactDOM.createPortal(
    <ModalBackground
      withBackground={withBackground}
      fadeOut={fadeOut}
      justifyContent={justifyContent}
    >
      {classes ? (
        <div className={classes}>{children({ innerModalRef })}</div>
      ) : (
        children({ innerModalRef })
      )}
    </ModalBackground>,
    containerRef.current
  )
}

const ModalBackground = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  animation: ${({ fadeOut }) =>
    fadeOut ? 'fadeOut 0.3s forwards ease-in-out' : 'fadeIn 0.3s forwards ease-in-out'};
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  > div {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
    }
    ::-webkit-scrollbar-button {
      display: none;
    }
  }
`

Modal.defaultProps = {
  portalRef: typeof window !== 'undefined' && document.body,
  withBackground: true,
  shouldCloseOnClickOutside: true,
  shouldCloseOnEscape: true,
  justifyContent: 'center'
}

Modal.propTypes = {
  withBackground: PropTypes.bool,
  portalRef: PropTypes.object,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func,
  classes: PropTypes.string,
  shouldCloseOnClickOutside: PropTypes.bool,
  shouldCloseOnEscape: PropTypes.bool
}

export { Modal }
