import styled, { keyframes, css } from 'styled-components'

import { Image } from '../Image'

const Loader = ({ size, variant }) =>
  variant === 'white' ? (
    <Loading variant={variant} src='/static/images/white-loader.svg' size={size} alt='loading' />
  ) : (
    <Loading
      variant={variant}
      src='/static/images/match-maker/loader.svg'
      size={size}
      alt='loading'
    />
  )

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loading = styled(Image)`
  animation: 2s ${rotate} linear infinite;
`

Loader.defaultProps = {
  variant: 'primary',
  size: '40px'
}

export { Loader }
