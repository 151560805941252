import styled from 'styled-components'
import Slider from 'react-slick'

export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .slick-slide {
    div:focus {
      outline: none;
    }
    img:focus {
      outline: none;
    }
  }
  .slick-prev:before {
    content: url('static/images/left-arrow-banner.svg');
  }
  .slick-next:before {
    content: url('static/images/right-arrow-banner.svg');
  }
`
