import styled from 'styled-components'
import { border, borderColor, variant } from 'styled-system'

import { Column, Row } from '../Grid'
import { FilledInfo } from '../Icons'

const TOP = 'top'
const RIGHT = 'right'
const DOWN = 'down'
const LEFT = 'left'

const Tooltip = ({ hoverElement, children, variant, outlined, ...props }) => {
  return (
    <TooltipCard data-testid='tooltip-card-id'>
      <TooltipLayer>
        <TooltipHover data-testid='tooltip-hover-id'>
          {hoverElement ? (
            hoverElement
          ) : (
            <FilledInfo
              color='#bbc1c5'
              backgroundColor={outlined ? 'transparent' : '#f5f5f5'}
              size='16px'
            />
          )}
        </TooltipHover>
        <TooltipBox data-testid='tooltip-box-id' variant={variant} {...props}>
          {children}
        </TooltipBox>
      </TooltipLayer>
    </TooltipCard>
  )
}

const TooltipHover = styled(Column)`
  cursor: pointer;
`
const TooltipBox = styled(Column)(
  {
    position: 'absolute',
    zIndex: '1',
    visibility: 'hidden'
  },
  variant({
    variants: {
      [TOP]: {
        bottom: '21px',
        left: '-63px'
      },
      [RIGHT]: {
        top: '5px',
        left: '20px'
      },
      [DOWN]: {
        top: '21px',
        left: '-63px'
      },
      [LEFT]: {
        top: '5px',
        right: '20px'
      }
    }
  })
)

const TooltipCard = styled(Row)`
  & ${TooltipHover}:hover + ${TooltipBox} {
    display: flex;
    position: absolute;
    visibility: visible;
  }
`

const TooltipLayer = styled(Row)`
  position: relative;
`
export { Tooltip }
