import React, { useCallback } from 'react'

import { Row, Column } from 'components/v2/Grid'
import { Title } from 'components/v2/Title'
import { Text } from 'components/v2/Text'
import { Button } from 'components/v2/Button'
import LazyImage from 'components/shared/LazyImage'
import { ImageBox, LinkBlog } from './styles'

const Blog = ({ posts }) => {
  const openLink = useCallback(
    link => {
      window.open(link)
    },
    [posts]
  )

  return (
    <section id='news-blog'>
      <Row px='3' maxWidth='1200px' margin='0 auto' py={['5', '6']} flexWrap='wrap'>
        <Row justifyContent='center' width='100%' mb='5' textAlign='center'>
          <Text fontSize={['24px', '32px']} fontWeight='600' color='#232936'>
            Vittude Blog: Fique por dentro
          </Text>
        </Row>

        <Row flexWrap='wrap' justifyContent='space-between'>
          {posts.map(post => (
            <Column
              key={post.id}
              onClick={() => openLink(post.link)}
              cursor='pointer'
              md={4}
              px={['0', '20px']}
            >
              <Title type='h3' color='#FF7708' fontWeight='600' fontSize='14px' mb='3'>
                {post.category.toUpperCase()}
              </Title>
              <LinkBlog rel='noreferrer' href={post.link} target='_blank'>
                <ImageBox>
                  <LazyImage style={{ maxWidth: '100%' }} src={post.image} alt={post.title} />
                </ImageBox>
              </LinkBlog>
              <Text
                color='#ABAFB6'
                fontSize='14px'
                fontWeight='600'
                lineHeight='20px'
                mt='3'
                mb='24px'
              >
                {post.date} | {Math.round(post.readTime)} min de leitura
              </Text>
              <LinkBlog rel='noreferrer' href={post.link} target='_blank'>
                <Title
                  type='h3'
                  color='#232936'
                  fontWeight='600'
                  fontSize='18px'
                  lineHeight='120%'
                  mb='3'
                >
                  {post.title.replace('&#8220;', '\u201C').replace('&#8221;', '\u201D')}
                </Title>
              </LinkBlog>
              <LinkBlog rel='noreferrer' href={post.link} target='_blank'>
                <Text variant='small' color='#232936' lineHeight='20px' fontSize='16px'>
                  {`${post.excerpt
                    .replace(/(<([^>]+)>)/gi, '')
                    .replace('&#8220;', '\u201C')
                    .replace('&#8221;', '\u201D')
                    .slice(0, 130)}${
                    post.excerpt
                      .replace(/(<([^>]+)>)/gi, '')
                      .replace('&#8220;', '\u201C')
                      .replace('&#8221;', '\u201D').length > 130
                      ? '...'
                      : ''
                  }`}
                </Text>
              </LinkBlog>
            </Column>
          ))}
        </Row>
        <Row flexWrap='wrap' minWidth='100%' justifyContent='center' mt='5'>
          <a rel='noreferrer' href={`${process.env.VITTUDE_OFFLINE_URL}/blog/`}>
            <Button variant='primary-outlined' height='44px' fontSize='14px' fontWeight='600'>
              Ver mais no blog
            </Button>
          </a>
        </Row>
      </Row>
    </section>
  )
}

export default Blog
