import React from 'react'

import Link from 'next/link'

import { Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { Button } from 'components/v2/Button'
import { BannerBox, InsideLink, Overlay } from './styles'

const Banner = () => (
  <section id='banner-corporate'>
    <Row width='100%' justifyContent='center'>
      <BannerBox>
        <Overlay>
          <Row p={['24px', '40px']} justifyContent='flex-end' flexDirection='column' width='100%'>
            <Row>
              <Text color='white' fontSize='32px' fontWeight='600' mb='2'>
                Ecossistema para gestão estratégica de saúde mental
              </Text>
            </Row>
            <Row>
              <Text lineHeight='24px' color='white' maxWidth='440px' mb='3' fontStyle='italic'>
                Soluções inteligentes em saúde mental
              </Text>
            </Row>
            <Link href={`${process.env.VITTUDE_OFFLINE_URL}/empresas`} passHref={true}>
              <InsideLink target='_blank' rel='noreferrer'>
                <Button
                  bg='white'
                  color='#232936'
                  height='48px'
                  fontSize='14px'
                  fontWeight='600'
                  lineHeight='19px'
                  variant='primary-refit'
                  id='test-banner'
                  fontStyle='italic'
                >
                  Saiba Mais
                </Button>
              </InsideLink>
            </Link>
          </Row>
        </Overlay>
      </BannerBox>
    </Row>
  </section>
)

export default Banner
