import styled from 'styled-components'
import { Row } from 'components/v2/Grid'

export const BannerBox = styled(Row)`
  background: url('/static/images/home-banner.webp');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
  margin-top: 80px;
  margin-bottom: 140px;
  min-height: 360px;

  @media (max-width: 768px) {
    margin-top: 64px;
    height: 493px;
    margin-bottom: 80px;
  }

  @media (max-width: 1024px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`

export const InsideLink = styled.a`
  width: 100%;
  z-index: 2;
`

export const Overlay = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  background: linear-gradient(84deg, rgba(0, 0, 0, 0.8) 8.33%, rgba(0, 0, 0, 0) 66.67%);
`
