import React from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  flexbox,
  grid,
  color,
  variant,
  typography,
  border,
  position
} from 'styled-system'
import PropTypes from 'prop-types'
import { Loader } from '../Loader'

const Button = ({ children, variant, disabled, onClick, loading, ...props }) => {
  return (
    <ButtonComponent
      variant={disabled ? 'ghost' : variant}
      disabled={disabled || loading}
      onClick={onClick}
      data-testid='button-component'
      {...props}
    >
      {loading ? <Loader size='32px' variant='white' /> : children}
    </ButtonComponent>
  )
}

const ButtonComponent = styled.button(
  ({ rounded }) => `
    height: 64px;
    border: 1px solid transparent;
    font-family: 'Sora', sans-serif;
    line-height: 24px;
    letter-spacing: 0.1px;
    padding: 0 30px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: ${rounded ? '4px' : '0px'};
  `,
  variant({
    variants: {
      primary: {
        bg: 'primary',
        color: '#fff',
        opacity: 0.9,
        '&:hover': {
          opacity: 1
        },
        '&:focus': {
          outline: '1px auto black'
        }
      },
      'primary-refit': {
        bg: 'primary',
        color: '#fff',
        '&:hover': {
          color: 'primary',
          bg: '#FFF'
        }
      },
      secondary: {
        bg: 'lightBlue',
        color: '#fff',
        opacity: 0.9,
        '&:hover': {
          opacity: 1
        },
        '&:focus': {
          outline: 'none'
        }
      },
      'light-secondary': {
        bg: '#CCE1FF',
        color: '#4491FF',
        opacity: 0.9,
        '&:hover': {
          opacity: 1
        },
        '&:focus': {
          outline: 'none'
        }
      },
      tertiary: {
        bg: '#fff',
        color: 'primary',
        '&:hover': {
          bg: 'white'
        },
        '&:focus': {
          outline: 'none'
        }
      },
      light: {
        bg: '#fff',
        color: '#313A4D',
        '&:hover': {
          color: 'primary'
        }
      },
      dark: {
        bg: '#1C1B1B',
        color: 'white'
      },
      'primary-outlined': {
        bg: 'white',
        borderColor: 'primary',
        color: 'primary',
        '&:hover': {
          color: 'white',
          bg: 'primary'
        }
      },
      'primary-outlined-active': {
        bg: '#FF770814',
        borderColor: 'primary',
        color: 'primary',
        '&:focus': {
          outline: 'none'
        }
      },
      'primary-outlined-inactive': {
        bg: '#fff',
        borderColor: '#bdc3c7',
        color: '#7f8c8d',
        '&:hover': {
          color: 'primary',
          borderColor: 'primary',
          bg: '#FF770814'
        },
        '&:focus': {
          outline: 'none'
        }
      },
      'secondary-outlined': {
        bg: '#fff',
        borderColor: 'lightBlue',
        color: 'lightBlue',
        '&:hover': {
          bg: 'white'
        },
        '&:focus': {
          outline: 'none'
        }
      },
      'white-outlined': {
        bg: 'primary',
        borderColor: '#fff',
        color: '#fff',
        '&:hover': {
          bg: 'white',
          color: 'primary'
        }
      },
      'white-outlined-gray': {
        bg: 'white',
        borderColor: '#E5E3E9',
        color: '#1C1B1B',
        '&:hover': {
          bg: '#EBEBEB'
        }
      },
      ghost: {
        bg: 'gray.n200',
        color: 'gray.n300',
        cursor: 'not-allowed',
        '&:focus': {
          outline: 'none'
        }
      },
      text: {
        bg: '#ffffff00',
        color: 'primary',
        '&:hover': {
          bg: '#ffffff00',
          color: 'primary'
        }
      }
    }
  }),
  space,
  layout,
  flexbox,
  grid,
  color,
  typography,
  border,
  position
)

ButtonComponent.defaultProps = {
  variant: 'primary',
  rounded: true,
  type: 'button',
  disabled: false
}

ButtonComponent.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'primary-outlined',
    'secondary-outlined',
    'ghost',
    'primary-outlined-active',
    'primary-outlined-inactive',
    'light',
    'white-outlined',
    'primary-refit'
  ]),
  rounded: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

export { Button }
