import styled from 'styled-components'

export const Image = styled.div`
  width: 100px;

  img {
    transition: 0.6s;
    cursor: pointer;
    width: auto !important;
    filter: grayscale(1);
    opacity: 0.4 !important;
    @media (max-width: 600px) {
      max-height: 22px;
      margin-bottom: 24px;
    }
  }
  .lazy-img {
    &:hover,
    &.active-logo {
      filter: unset;
      opacity: 1 !important;
    }
  }
`
