import React from 'react'
import { Row, Column } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import dynamic from 'next/dynamic'
import { Button } from 'components/v2/Button'

const LazyImage = dynamic(() => import('components/shared/LazyImage'), {
  ssr: false
})

const WhyDoTherapy = props => (
  <section id='why-do-therapy'>
    <Row flexWrap='wrap' minWidth='100%' py='5'>
      <Row px='3' maxWidth='1200px' m='0 auto' flexWrap='wrap'>
        <Column sm='12' md='6' px='2'>
          <Row mb={['24px', '0px']}>
            <LazyImage src='/static/images/home-whydotherapy.png' alt='Porque fazer tereapia' />
          </Row>
        </Column>
        <Column sm='12' md='6' px='2'>
          <Row flexWrap='wrap' minWidth='100%' justifyContent={'between'}>
            <Text type='h3' color='#232936' fontWeight='600' mb='3' fontSize={['24px', '32px']}>
              Saúde mental é inegociável!
            </Text>
            <Text variant='regular' color='#232936' lineHeight='24px' fontWeight='bold' mb='16px'>
              Já somos o 1º país mais ansioso do mundo e o 5º com mais casos de depressão
              diagnosticados. Até quando?!
            </Text>
            <Text variant='regular' color='#232936' mb='24px' lineHeight='24px' fontWeight='bold'>
              Estamos aqui para mudar esse jogo e reinventar um futuro pautado no progresso saudável
              e sustentável da sociedade. Você está junto com a gente nessa missão?
            </Text>
            <Row flexWrap='wrap' minWidth='100%'>
              <a rel='noreferrer' href={`${process.env.VITTUDE_OFFLINE_URL}/busca`}>
                <Button variant='primary-outlined' height='44px' fontSize='14px' fontWeight='600'>
                  Começar agora
                </Button>
              </a>
            </Row>
          </Row>
        </Column>
      </Row>
    </Row>
  </section>
)

export default WhyDoTherapy
