import React from 'react'
import { Row, Column } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import dynamic from 'next/dynamic'
import { Button } from 'components/v2/Button'

const LazyImage = dynamic(() => import('components/shared/LazyImage'), {
  ssr: false
})

const HowItWorks = () => (
  <section id='how-works'>
    <Row px={['17px', '10px']} maxWidth='1200px' margin='0 auto' mb='140px' flexWrap='wrap'>
      <Row flexWrap='wrap' justifyContent={'between'} mb='5'>
        <Column sm='12' md='7' mb={['32px', '0px']} alignItems='center'>
          <LazyImage
            src='/static/images/home-howitworks1.webp'
            alt='Um grupo de pessoas com a camiseta da Vittude se abraçando'
          />
        </Column>
        <Column sm='12' md='5'>
          <Text fontSize={['24px', '32px']} fontWeight='600' color='#232936' mb='3'>
            Melhor rede de especialista em saúde mental do Brasil
          </Text>
          <Text color='#232936' variant='regular' lineHeight='24px' mb='3'>
            Na Vittude você encontra psicólogos que são especialistas. Além do alto rigor na seleção
            e credenciamento dos profissionais, oferecemos oportunidade desenvolvimento e a melhor
            remuneração do mercado. Tudo isso em um ambiente 100% seguro para que você inicie sua
            jornada terapêutica de onde estiver com tranquilidade e ética em todos os níveis.
          </Text>
        </Column>
      </Row>
      <Row flexWrap='wrap' justifyContent={'between'} mt='5'>
        <Column sm='12' md='5' order={[2, 1]}>
          <Text fontSize={['24px', '32px']} fontWeight='600' color='#232936' mb='3'>
            Como funciona a <br />
            terapia online
          </Text>
          <Text color='#232936' variant='regular' lineHeight='24px' mb='4'>
            Na Vittude você encontra um ambiente 100% seguro, exclusivo para o seu atendimento.
            Somente você e o psicólogo tem acesso à sessão e aos dados e informações trocados nela.
            Nenhuma sessão é gravada, respeitando o princípio da confidencialidade entre psicólogo e
            cliente.
          </Text>
          <a rel='noreferrer' href={`${process.env.VITTUDE_OFFLINE_URL}/busca`}>
            <Button variant='primary' height='44px' fontSize='14px' fontWeight='600' id='start-now'>
              Começar agora
            </Button>
          </a>
        </Column>
        <Column sm='12' md='7' order={[1, 2]} mb={['32px', '0px']}>
          <LazyImage
            src='/static/images/home-howitworks2.webp'
            alt='Duas pessoas fazendo uma video-chamada'
          />
        </Column>
      </Row>
    </Row>
  </section>
)

export default HowItWorks
